const theme = {
  colors: {
    backgroundFooter: '#040212',
    backgroundPink: '#fe3a7d',
    coral: '#ff5a4d',
    backgroundOrange: '#ff7b0a',
    backgroundGradient: 'linear-gradient(45deg, #ff7b0a)',
    lightYellow: '#FDF9DF',
  },
  fonts: {
    titleFont: 'Nunito Sans, sans-serif',
    bodyFont: 'Overpass, sans-serif',
  },
  size: {
    xs: '1.7rem',
    s: '2rem',
    m: '2.4rem',
    l: '3rem',
    xl: '3.5rem'
  }
};

module.exports = theme;
