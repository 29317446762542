import React from "react";
import { graphql} from "gatsby";
import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Project from "../components/Project";
import styled from "styled-components";
import theme from "../styles";
import {Helmet} from "react-helmet";

export default ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Portfolio | Diana Ignatescu</title>
      <link rel="canonical" href="https://dianaignatescu.com/portfolio" />
    </Helmet>
    <Navigation />
    <PortfolioHeader>
      <h2>Check out my latest web projects.</h2>
    </PortfolioHeader>
    <ProjectsWrapper>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Project
          key={node.id}
          title={node.frontmatter.title}
          body={node.frontmatter.description}
          fluid={node.frontmatter.image.childImageSharp.fluid}
          technologies={node.frontmatter.technologies}
          github={node.frontmatter.github}
          website={node.frontmatter.website}
          demo={node.frontmatter.demo}
        />
      ))}
    </ProjectsWrapper>
    <Footer />
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            github
            website
            technologies
            demo
          }
          excerpt
        }
      }
    }
  }
`;

const PortfolioHeader = styled.div`
  width: 800px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0 2.5rem;
  }

  h2 {
    color: ${theme.colors.lightYellow};
    line-height: normal;
  }
`;

const ProjectsWrapper = styled.div`
  margin: 0 auto;
  width: 800px;
  padding-bottom: 3rem;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
