import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import theme from "../styles";

const Navigation = () => (
  <NavWrapper>
    <nav>
      <Link to="/">Home</Link>
      <Link to="/portfolio">Portfolio</Link>
      <Link to="/contact">Contact</Link>
      <Link to="/blog">Blog</Link>
    </nav>
  </NavWrapper>
);
export default Navigation;

const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
  @media (max-width: 800px) {
    width: 100%;
    padding: 2rem 2.5rem;
    margin: 0;
  }

  nav {
    display: flex;
    justify-content: flex-end;
    width: 200px;
    @media (max-width: 600px) {
      width: 100%;
      justify-content: space-between;
    }

    a {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 300;
      color: ${theme.colors.lightYellow};
      font-size: 1.7rem;
      padding: 1rem 1.5rem;
      @media (max-width: 600px) {
        padding: 1rem 1rem 1rem 0;
      }
      @media (max-width: 400px) {
      font-size: 1.4rem;
      }
      &:first-child {
      padding-left: 0;
      }
      &:last-child {
      padding-right: 0;
      }
      &:hover {
        cursor: pointer;
        transition: 1s ease;
        text-decoration: underline;
        text-decoration-color: ${theme.colors.lightYellow};
      }
    }
  }
`;
