import React, { Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import theme from "../styles";

const GlobalStyle = createGlobalStyle`

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
\tmargin: 0;
\tpadding: 0;
\tborder: 0;
\tfont-size: 100%;
\tfont: inherit;
\tvertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
\tdisplay: block;
}
body {
\tline-height: 1;
}
ol, ul {
\tlist-style: none;
}
blockquote, q {
\tquotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
\tcontent: '';
\tcontent: none;
}
table {
\tborder-collapse: collapse;
\tborder-spacing: 0;
}

*,
*::after,
*::before { 
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  }
body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  background: linear-gradient(45deg, ${theme.colors.backgroundPink}, ${theme.colors.backgroundOrange});
  background-attachment: fixed;
}

html {
font-size: 62.5%;
}

h1, h2, h3, h4, h5, h6 {
font-family: Nunito Sans, sans-serif;
font-weight: 900;
font-size: 3.5rem;
line-height: 3;
}

p, a, span, input, label, textarea {
font-family: 'Heebo', sans-serif;
font-weight: 100;
font-size: 1.7rem;
line-height: 1.6;
}

* {
  box-sizing: border-box;
  outline: none;
  line-height: 2.9rem;
}
`;

const Layout = ({ children }) => (
  <Fragment>
    <Wrapper>
      <GlobalStyle />
      {children}
    </Wrapper>
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
