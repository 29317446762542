import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import styled from "styled-components";
import theme from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab, faEnvelope);

const Footer = () => (
  <FooterWrapper>
    <div className="footer-contents">
      <span>Footer</span>
      <p>©2019, Built by Diana Ignatescu with <OutboundLink href="https://www.gatsbyjs.org" id="gatsby-link">Gatsby</OutboundLink></p>
      <SocialIcons>
        <OutboundLink href="https://github.com/DianaIgnatescu">
          <FontAwesomeIcon icon={["fab", "github"]} />
        </OutboundLink>
        <OutboundLink href="https://www.linkedin.com/in/dianaignatescu/">
          <FontAwesomeIcon icon={["fab", "linkedin"]} />
        </OutboundLink>
        <OutboundLink href="mailto:diana.ignatescu8@gmail.com">
          <FontAwesomeIcon icon={"envelope"} />
        </OutboundLink>
      </SocialIcons>
    </div>
  </FooterWrapper>
);
export default Footer;

const FooterWrapper = styled.div`
  max-width: 100%;
  margin: 0;
  padding: 1.5rem 0;
  background: ${theme.colors.backgroundFooter};
  .footer-contents {
    width: 800px;
    margin: 0 auto;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
      width: 100%;
      padding: 0 2.5rem;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
    }
    span {
      width: 100px;
      height: 30px;
      visibility: hidden;
      background: red;
      @media (max-width: 800px) {
        display: none;
      }
    }
    p {
      color: white;
      opacity: 0.6;
      width: auto;
      text-align: center;
      font-family: "Heebo", sans-serif;
      font-weight: 100;
      @media (max-width: 600px) {
        position: initial;
        order: 2;
        padding: 1rem 0;
      }
    }
    a {
      color: ${theme.colors.lightYellow};
      &:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s ease;
        color: ${theme.colors.coral};
      }
    }
    #gatsby-link {
      color: ${theme.colors.coral};
      &:hover {
        color: ${theme.colors.lightYellow};
      }
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100px;
  a {
    color: ${theme.colors.lightYellow};
    opacity: 0.6;
    font-size: 2.4rem;
    padding-right: 1.5rem;
    &:hover {
      cursor: pointer;
      opacity: 1;
      transition: 0.3s ease;
      color: ${theme.colors.coral};
    }
  }
`;
