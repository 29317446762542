import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import theme from "../styles";

const Project = ({
  fluid,
  title,
  body,
  technologies,
  github,
  website,
  demo
}) => (
  <ProjectWrapper>
    <ImageWrapper>
      <StyledImg fluid={fluid} />
    </ImageWrapper>

    <ProjectInfo>
      <h2>{title}</h2>
      <p>{body}</p>
      <TechnologiesWrapper>
        {technologies.map(technology => (
          <span className="tag is-primary is-medium" key={technology}>
            | {technology} |{" "}
          </span>
        ))}
      </TechnologiesWrapper>
      <ProjectLinks>
        {website !== "" ? (
          <a href={website} target="_blank" rel="noopener noreferrer">
            Website
          </a>
        ) : (
          <></>
        )}
        {github !== "" ? (
          <a href={github} target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
        ) : (
          <></>
        )}
        {demo !== "" ? (
          <a href={demo} target="_blank" rel="noopener noreferrer">
            Demo
          </a>
        ) : (
          <></>
        )}
      </ProjectLinks>
    </ProjectInfo>
  </ProjectWrapper>
);

export default Project;

const ProjectWrapper = styled.div`
  display: flex;
  padding: 4rem 0;
  color: ${theme.colors.lightYellow};
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 4rem 2.5rem;
  }
`;

const ImageWrapper = styled.div`
  width: 60%;
  position: relative;
  background: inherit;
  border-radius: 2px;
  overflow: hidden;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    position: absolute;
    bottom: 0;
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.09);
    filter: blur(1px);
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledImg = styled(Img)`
  margin: 2rem;
  border-radius: 2px;
  z-index: 2;
  box-shadow: 0 55px 50px 17px rgba(0, 0, 0, 0.03);
  @media (max-width: 800px) {
    max-width: 100%;
    margin: 2rem;
  }
`;

const TechnologiesWrapper = styled.div`
  margin-top: 2rem;
  span {
    padding-right: 10px;
    font-size: 1.7rem;
  }
`;

const ProjectLinks = styled.div`
  margin-top: 2.5rem;
  a {
    text-decoration: none;
    color: ${theme.colors.backgroundPink};
    font-weight: 300;
    font-size: 2rem;
    padding: 0 1rem;
    margin-right: 1rem;
    background: ${theme.colors.lightYellow};
    &:hover {
      cursor: pointer;
      transition: 0.3s ease;
      color: ${theme.colors.backgroundOrange};
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }
`;

const ProjectInfo = styled.div`
  width: 40%;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    line-height: normal;
    @media (max-width: 800px) {
      line-height: 2;
    }
  }
  p {
    font-size: 2rem;
  }
`;
